<template>
    <div class="p1" id="a3">
        <div class="header-search">
            <div class="title">
                <el-row>
                    <el-col :span="12">
                        <span class="line"></span>
                        小程序开票信息
                    </el-col>
                    <el-col :span="12">
                        <div class="flex align-center justify-end h50">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="header-input">
            <div class="model-mask">
                <div
                        class="header-search-box flex align-center justify-end mt20"
                >
                    <el-form :inline="true">
                        <el-form-item>
                            <el-input
                                    placeholder="下级员工|AD|汇报范围|上级员工|AD|汇报范围 "
                                    prefix-icon="el-icon-search"
                            >
                                <el-button
                                        slot="append"
                                        icon="el-icon-search"
                                ></el-button>
                            </el-input>
                        </el-form-item>
                        <el-button
                                type="primary"
                                size="small"
                                plain
                                class="is-circle"
                        ><i class="el-icon-plus fw900 f16"></i
                            ></el-button>
                    </el-form>
                </div>


                <!-- 表格 -->
                <!-- 用户列表区域 -->
                   <div class="header-input">
                               <el-form
                                        :model="memberBill"
                                        ref="memberBill"
                                        label-width="150px"
                                        class="memberBill"
                                        size="mini"
                                >
                                    <el-row>
                                            <el-col :sm="24" :md="24" :lg="12"   style="display:none"  >
                                                <el-form-item label="id" prop="id">
                                                    <el-input v-model="memberBill.id"></el-input>
                                                </el-form-item>
                                            </el-col>
                                             <el-col>
                                                <el-form-item label="审批状态">
                                                <el-select v-model="memberBill.billType"  >
                                                <!-- <el-option label="未审核" value="1"></el-option> -->
                                                <el-option label="企业单位" :value=1></el-option>
                                                 <el-option label="个人/非企业" :value=2></el-option>                                                
                                                 </el-select>
                                                </el-form-item>
                                            </el-col> 
                                            </el-row><el-row>
                                            <el-col :sm="24" :md="24" :lg="12" class="pt" v-show="memberBill.billType==1" >
                                                <el-form-item label="公司名称" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>  
                                            <el-col :sm="24" :md="24" :lg="12"  class="pt" >
                                                <el-form-item label="公司地址" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24" :md="24" :lg="12" class="pt" v-show="memberBill.billType==1" >
                                                <el-form-item label="公司税号" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col> 
                                            <el-col :sm="24" :md="24" :lg="12" class="pt"  >
                                                <el-form-item label="公司电话" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>    
                                            <el-col :sm="24" :md="24" :lg="12"  class="pt" v-show="memberBill.billType==1">
                                                <el-form-item label="公司开户行" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>   
                                            <el-col :sm="24" :md="24" :lg="12" class="pt" v-show="memberBill.billType==1" >
                                                <el-form-item label="开户行账号" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>    
                                            <el-col :sm="24" :md="24" :lg="12"  class="pt" >
                                                <el-form-item label="备注" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>  

                                            <el-col :sm="24" :md="24" :lg="12" class="pt" v-show="memberBill.billType==2" >
                                                <el-form-item label="名称" prop="name">
                                                    <el-input v-model="memberBill.name"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24" :md="24" :lg="12"  class="pt" >
                                                <el-form-item label="电子邮箱" prop="dutyNo">
                                                    <el-input v-model="memberBill.phone"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24" :md="24" :lg="12" class="pt"  >
                                                <el-form-item label="总金额" prop="address">
                                                    <el-input v-model="memberBill.price"></el-input>
                                                </el-form-item>
                                            </el-col>                                                                                                                                
                                    </el-row>
                                </el-form>
                            
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
              tableData: [],
              memberBill: {
            id:"",
            billType:"",
            name:"",
            dutyNo:"",
            address:"",
            phone:"",
            bankDeposit:"",
            bankAccountNo:"",
            remark:"",
            price:"",
            email:"",
            createDt:"",
            orderNumber:"",
        },
        }
    },
  methods:{
      loadBaseInfo(){
      let that=this;
      if(this.id!=undefined&&this.id!=''){
         that.$http.post("/memberBill/queryOne", {id:this.id}).then(function (response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.memberBill = response.data.data;
          }
        });
      }
      },
  },
  created() {
     if (this.$route.query.id != undefined&&this.$route.query.id != "") {
      this.id =this.$route.query.id;
    }
    this.currPage=this.$route.query.currPage;
    this.pageSize=this.$route.query.pageSize;
    this.loadBaseInfo()
  },
}

</script>
<style lang="less" scope>
    .pt {
        padding-top:20px
    }
    .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
</style>

